import { defineNuxtRouteMiddleware, navigateTo } from '#imports';

const LOGIN_PATH = '/login';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const session = useSession();

  const apiClient = useApi();

  const [userSession, userSessionError] = await apiClient.user.getUserSession();

  if (userSessionError && to.path != LOGIN_PATH) {
    const sessionCookie = useCookie('session');

    sessionCookie.value = null;

    return navigateTo(LOGIN_PATH);
  } else {
    session.set(userSession);
  }
});

